import React from "react";
import styles from "./App.module.css";

const RuleInput = ({ onRuleChange, value }) => (
  <input
    type="text"
    className={styles.ruleInput}
    placeholder="Enter a rule"
    value={value}
    onChange={(e) => onRuleChange(e.target.value)}
  />
);

export default RuleInput;
