import axios from "axios";

const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
console.log("OpenAI API Key:", OPENAI_API_KEY);
const OPENAI_API_BASE_URL = "https://api.openai.com/v1/completions";

export const refactorCode = async (inputCode, rules) => {
  const prompt = `Refactor the following code according to these rules: ${rules.join(
    ", "
  )}\n\nInput code:\n${inputCode}\n\nRefactored code:`;

  const requestData = {
    model: "text-davinci-003",
    prompt,
    max_tokens: 1000,
    n: 1,
    stop: null,
    temperature: 0.8,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${OPENAI_API_KEY}`,
    },
  };

  try {
    const response = await axios.post(OPENAI_API_BASE_URL, requestData, config);
    const refactoredCode = response.data.choices[0]?.text.trim();
    return refactoredCode;
  } catch (error) {
    console.error("Error calling OpenAI API:", error);
    return "erroe?";
  }
};
