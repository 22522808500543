import React from "react";
import RuleInput from "./RuleInput";
import styles from "./App.module.css";

const RuleList = ({ rules, onRuleChange, onRuleRemove, handleAddRule }) => (
  <div className={styles.ruleList}>
    {rules.map((rule, index) => (
      <div key={index} className={styles.ruleContainer}>
        <RuleInput
          key={index}
          value={rule}
          onRuleChange={(value) => onRuleChange(index, value)}
        />
        <button
          className={styles.removeRuleButton}
          onClick={() => onRuleRemove(index)}
        >
          x
        </button>
      </div>
    ))}

    <button className={styles.addRuleButton} onClick={handleAddRule}>
      +
    </button>
  </div>
);

export default RuleList;
