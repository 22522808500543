import React, { useState } from "react";
import RuleList from "./RuleList";
import styles from "./App.module.css";
import { refactorCode } from "./openaiApi";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";

function App() {
  const [rules, setRules] = useState([""]);
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");

  const handleRuleChange = (index, value) => {
    const newRules = [...rules];
    newRules[index] = value;
    setRules(newRules);
  };

  const handleAddRule = () => {
    if (rules.length >= 5) return alert("You can only add up to 5 rules");
    setRules([...rules, ""]);
  };

  const handleRemoveRule = (index) => {
    setRules((prevRules) => prevRules.filter((_, i) => i !== index));
  };

  const handleInputChange = (newValue) => {
    setInputText(newValue);
  };

  const handleButtonClick = async () => {
    setOutputText("Processing...");
    const refactoredCode = await refactorCode(inputText, rules);
    setOutputText(refactoredCode);
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Refactorizer</h1>
      <div className={styles.textAreaContainer}>
        <AceEditor
          mode="javascript"
          theme="monokai"
          value={inputText}
          onChange={handleInputChange}
          fontSize={14}
          showPrintMargin={false}
          showGutter={true}
          highlightActiveLine={true}
          className={styles.textArea}
          setOptions={{
            enableBasicAutocompletion: false,
            enableLiveAutocompletion: false,
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 2,
          }}
        />

        <RuleList
          rules={rules}
          onRuleChange={handleRuleChange}
          onRuleRemove={handleRemoveRule}
          handleAddRule={handleAddRule}
        />
        <AceEditor
          mode="javascript"
          theme="monokai"
          value={outputText}
          readOnly={true}
          fontSize={14}
          showPrintMargin={false}
          showGutter={true}
          highlightActiveLine={false}
          className={styles.textArea}
          setOptions={{
            enableBasicAutocompletion: false,
            enableLiveAutocompletion: false,
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 2,
          }}
        />
      </div>
      <button className={styles.processButton} onClick={handleButtonClick}>
        Process
      </button>
    </div>
  );
}

export default App;
